export const removeExtraSpaces = (input: string) => {
  return input.replace(/  +/g, ' ');
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noopHandler(): void {}

export function formatQuantityString(input: number): number {
  if (input % 1 !== 0) {
    return Math.round(input * 1000) / 1000;
  } else {
    return input;
  }
}
