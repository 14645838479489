import { PrinterSeries } from '@oolio-group/domain';
import { getPrintableKitchenOrder } from '../../utils/printing/kitchenReceiptLayout';
import {
  WorkerInput,
  BufferHandlerResult,
  PrintKitchenDocketWorkerInput,
  DEFAULT_NET_PRINTER_PORT,
} from '../utils';

export const printKitchenDocketHandler = (
  message: WorkerInput,
): BufferHandlerResult[] => {
  const results: BufferHandlerResult[] = [];

  const {
    docket,
    printingOptions,
    session,
    docketItems = [],
    translatedNowCourse,
  } = message.data as PrintKitchenDocketWorkerInput;

  const { printer, template, profile } = printingOptions;

  const profileName = profile.name;
  const locale = profile.locale;

  const printablePartialOrders: (Buffer | undefined)[] = [];

  if (profile.singleItemPrinting) {
    // if single item printing
    if (docketItems.length) {
      let currentItemNumber = 0;
      const totalItems = docketItems
        .map(e => {
          return e.quantity || 1;
        })
        .reduce((sum, e) => sum + e, 0);
      docketItems.forEach(newItem => {
        if (Number.isInteger(newItem.quantity)) {
          // if qty is integer
          // add each qty of the item to a docket
          for (let i = 0; i < (newItem.quantity || 1); i++) {
            currentItemNumber++;
            printablePartialOrders.push(
              getPrintableKitchenOrder({
                docket,
                printItems: [{ ...newItem, quantity: 1 }],
                session,
                template,
                translatedNowCourse,
                profileName,
                locale,
                printerSeries: printer.series || PrinterSeries.TM_M30II,
                singleItemPrinting: true,
                currentItemNumber: currentItemNumber,
                totalItems: totalItems,
              }),
            );
          }
        } else {
          // if qty is not integer
          // add each item to docket
          currentItemNumber++;
          printablePartialOrders.push(
            getPrintableKitchenOrder({
              docket,
              printItems: [{ ...newItem }],
              session,
              template,
              translatedNowCourse,
              profileName,
              locale,
              printerSeries: printer.series || PrinterSeries.TM_M30II,
              singleItemPrinting: true,
              currentItemNumber: currentItemNumber,
              totalItems: totalItems,
            }),
          );
        }
      });
    }
  } else {
    if (docketItems.length) {
      printablePartialOrders.push(
        getPrintableKitchenOrder({
          docket,
          printItems: docketItems,
          session,
          template,
          translatedNowCourse,
          profileName,
          locale,
          printerSeries: printer.series || PrinterSeries.TM_M30II,
          singleItemPrinting: false,
        }),
      );
    }
  }

  printablePartialOrders.forEach(printablePartialOrder => {
    printablePartialOrder &&
      results.push({
        printer: {
          id: printer.id,
          device_name: printer.name,
          host: printer.ipAddress,
          port: DEFAULT_NET_PRINTER_PORT,
          type: printer.printerType,
          bdAddress: printer.bdAddress,
          slug: printer.slug,
          series: printer.series,
          brand: printer.brand,
        },
        buffer: printablePartialOrder,
      });
  });

  return results;
};
