import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';
import { DocketStatus } from '@oolio-group/domain';
import {
  getTimeDifference,
  minifyHourText,
  minifyMinuteText,
  convertToMinutes,
} from '../../../utils/timeHelper';
import moment from 'moment';
import DocketItemStyles from '../DocketItem.styles';

export interface DocketItemTimerProps {
  createdTime: string;
  status: DocketStatus;
}

const DocketItemTimer: React.FC<DocketItemTimerProps> = ({
  createdTime,
  status,
}) => {
  const styles = DocketItemStyles();
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    if (status == DocketStatus.CREATED) {
      const interval = setInterval(() => setTime(Date.now()), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [status]);
  const duration =
    status === DocketStatus.CREATED
      ? convertToMinutes(
          getTimeDifference(time, Number(createdTime)) || '0:00:00',
        )
      : (() => {
          const timeFromNow = moment(createdTime).fromNow();
          const minifiedHour = minifyHourText(timeFromNow);
          const minifiedMinute = minifyMinuteText(minifiedHour);
          return minifiedMinute;
        })();

  const formattedCreatedTime = moment(createdTime).format('HH:mm');

  return (
    <Text testID="docket-duration" style={styles.text}>
      {`${duration} | ${formattedCreatedTime}`}
    </Text>
  );
};

export default DocketItemTimer;
